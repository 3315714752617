<template>
  <div class="cube-level cube-level--one" @mouseup="release" @touchend="release">
    <div ref="stage" class="game__stage" @mousemove="move" @touchmove="move">
      <div class="game__stage--static-line">
        <div class="game__static-line" data-left="0" data-top="0" data-span="2"></div>
        <div class="game__static-line" data-left="2" data-top="0" data-span="2" data-vertical></div>
        <div class="game__static-line" data-left="0" data-top="2" data-span="2"></div>
      </div>

      <div ref="staticSquareStage" class="game__stage--static-square">
        <img
          v-for="dot in flatAreas"
          :key="dot"
          class="game__dot"
          :data-area="dot"
          :src="dot === 'center-center' ? '/assets/img/connects/central.svg' : '/assets/img/connects/dot.svg'"
          :alt="`dot-${dot}`"
          draggable="false"
        >
      </div>

      <div class="game__stage--static-square">
        <div
          v-for="dot in flatAreas"
          :key="dot"
          class="game__square"
          :data-area="dot"
        />
      </div>

      <div ref="linesMovableStage" class="game__stage--lines-movable" />

      <div ref="movableStage" class="game__stage--movable">
        <div ref="bear" class="game__bear" :style="{ gridArea: bearPosition }" data-clickable="true" @mousedown="grab" @touchstart="grab" ></div>
        <div ref="heart" class="game__heart" :style="{ gridArea: heartPosition }"></div>
        <div ref="explosion" class="game__explosion" :style="{ gridArea: heartPosition }"></div>
      </div>

      <div ref="radiant" class="game__stage--radiant">
        <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect ref="radiantRect" x="125" y="125" width="250" height="250" rx="125"/>
        </svg>
      </div>

      <div class="game__stage--tutorial">
        <div class="game__stage--tutorial-explenation">
          <span>{{ $translate('connects').tutorial }}</span>
        </div>
        <img class="game__stage--tutorial-image" src="/assets/img/connects/tutorial-1.gif" alt="Tutorial">
      </div>
    </div>
  </div>
</template>

<script>
import logic from '@/mixins/connects-logic';

export default {
  name: 'LevelOne',

  mixins: [logic],

  mounted() {
    // Create the move map
    this.movesMap = new Map();
    this.movesMap.set('top-left', ['top-center']);
    this.movesMap.set('top-center', ['top-left', 'top-right']);
    this.movesMap.set('top-right', ['top-center', 'center-right']);
    this.movesMap.set('center-left', []);
    this.movesMap.set('center-center', []);
    this.movesMap.set('center-right', ['top-right', 'bottom-right']);
    this.movesMap.set('bottom-left', ['bottom-center']);
    this.movesMap.set('bottom-center', ['bottom-left', 'bottom-right']);
    this.movesMap.set('bottom-right', ['bottom-center', 'center-right']);

    // Add the movable ones
    this.movableMovesMap = new Map();
  },
};
</script>
