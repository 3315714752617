import gsap from 'gsap';
import Draggable from '@/scripts/gsap-plugin/Draggable';
import InertiaPlugin from '@/scripts/gsap-plugin/InertiaPlugin';
// import MotionPathPlugin from '@/scripts/gsap-plugin/MotionPathPlugin';

gsap.registerPlugin(Draggable, InertiaPlugin);

export default {
  data() {
    return {
      areas: [
        ['top-left', 'top-center', 'top-right'],
        ['center-left', 'center-center', 'center-right'],
        ['bottom-left', 'bottom-center', 'bottom-right'],
      ],
      bearPosition: 'bottom-left',
      heartPosition: 'top-left',
      movesMap: null,
      movableMovesMap: null,
      mouseDown: false,
      inRotation: false,
      rotation: 0,
      prevRotation: 0,
    };
  },

  computed: {
    flatAreas() {
      return this.areas.flat();
    },
  },

  mounted() {
    const linesMovableRotation = gsap.quickSetter(this.$refs.linesMovableStage, 'rotation', 'deg');
    const staticSquareRotation = gsap.quickSetter(this.$refs.staticSquareStage, 'rotation', 'deg');

    const radiantRectAngle = gsap.utils.pipe(
      gsap.utils.mapRange(0, 360, 760, 0),
      gsap.quickSetter(this.$refs.radiantRect, 'stroke-dashoffset', ''),
    );

    const rotationSnap = 90;
    Draggable.create('.game__stage--movable', {
      type: 'rotation',
      inertia: true,
      throwProps: false,
      throwResistance: 10000000,
      callbackScope: this,
      snap(endValue) {
        return Math.round(endValue / rotationSnap) * rotationSnap;
      },
      onRelease() {
        gsap.set(this.$refs.radiant, { opacity: 0 });
        gsap.set(this.$refs.radiantRect, { strokeDashoffset: 0 });
      },
      onDragStart(ev) {
        const angle = this.getInitialAngle(ev);
        gsap.set(this.$refs.radiant, { opacity: 0.25, rotate: angle });

        this.inRotation = true;
        gsap.set(this.$refs.bear, { pointerEvents: 'none' });
        gsap.set(this.$refs.heart, { pointerEvents: 'none' });
      },

      onDrag: this.updateLinesMovable,
      onThrowUpdate: this.updateLinesMovable,
      onDragParams: [linesMovableRotation, radiantRectAngle, staticSquareRotation],
      onThrowUpdateParams: [linesMovableRotation, radiantRectAngle, staticSquareRotation],

      onThrowComplete() {
        const rotation = gsap.getProperty(this.$refs.movableStage, 'rotate');
        const rotationLines = gsap.getProperty(this.$refs.linesMovableStage, 'rotate');
        const rotationMultiplayer = (rotation % 360) / 90;
        const rotationMultiplayerLines = (rotationLines % 360) / 90;

        const absoluteRotation = rotationMultiplayer > 0 ? 4 - rotationMultiplayer : Math.abs(rotationMultiplayer);
        const absoluteRotationLines = rotationMultiplayerLines > 0 ? 4 - rotationMultiplayerLines : Math.abs(rotationMultiplayerLines);

        this.rotation = absoluteRotationLines;
        this.setMovableArea(absoluteRotation);

        const bearAngle = this.getBearAngle();
        gsap.set(this.$refs.bear, { pointerEvents: 'auto', rotate: bearAngle + 90 });
        gsap.set(this.$refs.heart, { rotate: bearAngle + 90 });
        gsap.set(this.$refs.movableStage, { pointerEvents: 'auto', rotate: 0 });
        this.prevRotation = gsap.getProperty(this.$refs.linesMovableStage, 'rotate');
        this.inRotation = false;
      },
      dragClickables: false,
    });
  },

  methods: {
    updateLinesMovable(setRotation, setAngle, setRotationSquare) {
      const rotation = gsap.getProperty(this.$refs.movableStage, 'rotate');
      setAngle(rotation);
      setRotationSquare(rotation);
      setRotation(this.prevRotation + rotation);
    },
    transposeAreas(n) {
      let { areas } = this;
      for (let i = 0; i < n; i += 1) {
        // eslint-disable-next-line no-loop-func
        areas = areas[0].map((val, index) => areas.map((row) => row[index])
          .reverse());
      }
      return areas;
    },
    move(event) {
      if (!this.mouseDown) return;
      let target;

      if (event.type === 'touchmove') {
        const x = event.touches[0].clientX;
        const y = event.touches[0].clientY;
        target = document.elementFromPoint(x, y);
      } else {
        target = event.target;
      }

      if (!target.classList.contains('game__square')) return;

      const possibleMoves = this.movesMap.get(this.bearPosition);

      let possibleMovableMoves = [];
      if (this.movableMovesMap.has(this.bearPosition)) {
        possibleMovableMoves = this.movableMovesMap.get(this.bearPosition)[this.rotation];
      }

      const destination = target.dataset.area;
      const canMove = possibleMoves.includes(destination) || possibleMovableMoves.includes(destination);

      if (canMove) this.bearPosition = target.dataset.area;
      if (this.bearPosition === this.heartPosition) {
        setTimeout(() => {
          this.$audio.play('check');
        }, 200);
        this.$emit('level-up');
      }
    },
    grab() {
      this.$audio.play('click');
      gsap.set(this.$refs.stage, { cursor: 'grab' });
      gsap.set(this.$refs.movableStage, { pointerEvents: 'none' });
      this.mouseDown = true;
    },
    release() {
      gsap.set(this.$refs.stage, { cursor: 'move' });
      gsap.set(this.$refs.movableStage, { pointerEvents: 'auto' });
      this.mouseDown = false;
    },
    setMovableArea(rotation) {
      const bearAreaIndex = this.flatAreas.findIndex((area) => area === this.bearPosition);
      const bearRow = Math.floor(bearAreaIndex / this.areas.length);
      const bearColumn = bearAreaIndex % this.areas.length;

      const heartAreaIndex = this.flatAreas.findIndex((area) => area === this.heartPosition);
      const heartRow = Math.floor(heartAreaIndex / this.areas.length);
      const heartColumn = heartAreaIndex % this.areas.length;

      this.bearPosition = this.transposeAreas(rotation)[bearRow][bearColumn];
      this.heartPosition = this.transposeAreas(rotation)[heartRow][heartColumn];
      this.inRotation = false;
    },
    getInitialAngle(ev) {
      const {
        top: radiantTop,
        right: radiantRight,
        bottom: radiantBottom,
        left: radiantLeft,
      } = this.$refs.radiant.getBoundingClientRect();

      const p1 = { x: (radiantRight + radiantLeft) / 2, y: (radiantBottom + radiantTop) / 2 };

      const p2 = { x: ev.clientX, y: ev.clientY };

      const dx = p2.x - p1.x;
      const dy = p2.y - p1.y;
      const radAngle = Math.atan2(dy, dx);
      return radAngle * (180 / Math.PI) + 90;
    },
    getBearAngle() {
      /* eslint-disable object-curly-newline */
      const { top: bearTop, right: bearRight, bottom: bearBottom, left: bearLeft } = this.$refs.bear.getBoundingClientRect();
      const { top: heartTop, right: heartRight, bottom: heartBottom, left: heartLeft } = this.$refs.heart.getBoundingClientRect();

      const p1 = { x: (bearRight + bearLeft) / 2, y: (bearBottom + bearTop) / 2 };
      const p2 = { x: (heartRight + heartLeft) / 2, y: (heartBottom + heartTop) / 2 };

      const dx = p2.x - p1.x;
      const dy = p2.y - p1.y;
      const radAngle = Math.atan2(dy, dx);
      return radAngle * (180 / Math.PI) + 90;
    },
  },
};
