var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"game crosswords",on:{"mousemove":_vm.move,"touchmove":_vm.move}},[_c('transition',{attrs:{"css":false,"mode":"out-in"},on:{"leave":_vm.onLeave,"enter":_vm.onEnter}},[(_vm.win)?_c('div',{key:"win",staticClass:"game__win"},[_c('BaseTitleText',{attrs:{"content":_vm.$translate(_vm.gameEnded ? 'end-game' : 'crosswords').titleText}}),(_vm.gameEnded)?_c('BaseCta',{attrs:{"content":_vm.$translate('endGameCta'),"href":_vm.$translate('end-game').nextLink},nativeOn:{"mouseenter":function($event){return _vm.$eventBus.$emit((_vm.nextLink + "-enter"))},"mouseleave":function($event){return _vm.$eventBus.$emit((_vm.nextLink + "-leave"))}}}):_c('BaseCta',{attrs:{"content":_vm.$translate('nextGameCta'),"to":("/" + (_vm.$route.params.lang) + "/" + _vm.nextLink)},nativeOn:{"click":function($event){return _vm.$sendEvent('Select game', _vm.nextLink);},"mouseenter":function($event){return _vm.$eventBus.$emit((_vm.nextLink + "-enter"))},"mouseleave":function($event){return _vm.$eventBus.$emit((_vm.nextLink + "-leave"))}}})],1):_c('div',{key:"in-game",staticClass:"game__wrapper"},[_c('div',{ref:"stage",staticClass:"game__stage"},[_vm._l((_vm.letters.flat()),function(letter,index){return _c('span',{key:index,staticClass:"game__letter",attrs:{"data-row":Math.floor(index/_vm.letters.length),"data-column":index%_vm.letters.length},domProps:{"innerHTML":_vm._s(letter)},on:{"mousedown":_vm.down,"mouseup":_vm.up,"touchstart":_vm.down,"touchend":_vm.up}})}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.segment.width > 0),expression:"segment.width > 0"}],staticClass:"game__picker",style:({
            width: ((_vm.segment.width) + "px"),
            height: ((_vm.segment.height) + "px"),
            top: ((_vm.segment.top) + "px"),
            left: ((_vm.segment.left) + "px"),
            transform: ("rotate(" + (_vm.segment.rotate) + "deg)"),
            transformOrigin: _vm.segment.transformOrigin,
          })})],2),_c('div',{staticClass:"game__list"},_vm._l((_vm.words),function(word,index){return _c('span',{key:index},[_c('span',{staticClass:"game__list-word",attrs:{"data-word":word.toLowerCase()}},[_vm._v(_vm._s(word))]),(index !== _vm.words.length - 1)?_c('span',[_vm._v(" - ")]):_vm._e()])}),0),_c('div',{staticClass:"game__footer"},[_vm._v(_vm._s(_vm.$translate('crosswords').inGame))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }