<template>
  <div class="game memory">
    <transition
      :css="false"
      mode="out-in"
      @leave="onLeave"
      @enter="onEnter"
    >
      <div v-if="win" key="win" class="game__win" >
        <BaseTitleText :content="$translate('memory').titleText" />

        <BaseCta
          v-if="gameEnded"
          :content="$translate('endGameCta')"
          :href="$translate('end-game').nextLink"
          @mouseenter.native="$eventBus.$emit(`${nextLink}-enter`)"
          @mouseleave.native="$eventBus.$emit(`${nextLink}-leave`)"
        />
        <BaseCta
          v-else
          :content="$translate('nextGameCta')"
          :to="`/${$route.params.lang}/${nextLink}`"
          @click.native="$sendEvent('Select game', nextLink);"
          @mouseenter.native="$eventBus.$emit(`${nextLink}-enter`)"
          @mouseleave.native="$eventBus.$emit(`${nextLink}-leave`)"
        />
      </div>
      <div v-else key="in-game" class="game__wrapper" >
        <div class="game__stage">
          <div
            v-for="(card, index) in cards"
            :key="index"
            class="game__card"
            :data-index="index"
            :data-card="card.icon"
            :data-iteration="card.iteration"
            :class="{
              'active': card.icon == firstCard.icon && firstCard.iteration == card.iteration || card.icon == secondCard.icon && card.iteration == secondCard.iteration,
              'found': found.includes(index)
            }"
            @click="pick"
          >
            <div
              class="game__card-front"
              :style="{ 'background-image': `url('/assets/img/memory/${card.icon}.jpg')` }"
            ></div>
            <div class="game__card-back"><i></i></div>
          </div>
        </div>
        <div class="game__footer">{{ $translate('memory').inGame }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseTitleText from '@/components/BaseTitleText';
import BaseCta from '@/components/BaseCta';
import { shuffle } from 'lodash';
import { leave } from '@/scripts/animations';
import { enterBlockText } from '@/scripts/transitions/blockText';
import CardsEngine from '@/scripts/CardsEngine';

export default {
  name: 'Memory',

  components: {
    BaseTitleText,
    BaseCta,
  },

  data() {
    return {
      totalIcons: 12,
      found: [],
      firstCard: { index: null, icon: null, iteration: null },
      secondCard: { index: null, icon: null, iteration: null },
      stopped: false,
      win: false,
    };
  },

  computed: {
    cards() {
      const cards = [];
      const push = (i, k) => { cards.push({ icon: `icon-${i}`, iteration: k }); };
      for (let i = 0; i < this.totalIcons; i += 1) push(i, 1);
      for (let i = 0; i < this.totalIcons; i += 1) push(i, 2);
      return shuffle(cards);
    },
    gameEnded() {
      const endedArray = Object.values(this.$store.state.gameEnded);
      return endedArray.every((game) => game);
    },
    nextLink() {
      let link = 'galleria-strasburgo';

      if (this.$store.state.gameEnded.connects) link = 'via-chiossetto';

      return link;
    },
  },

  methods: {
    pick(event) {
      if (this.stopped) return;
      if (event.currentTarget.classList.contains('found')) return;
      this.$audio.play('click');

      if (!this.firstCard.icon) {
        this.firstCard.index = event.currentTarget.getAttribute('data-index');
        this.firstCard.icon = event.currentTarget.getAttribute('data-card');
        this.firstCard.iteration = event.currentTarget.getAttribute('data-iteration');
        return;
      }

      if (!this.secondCard.icon) {
        this.secondCard.index = event.currentTarget.getAttribute('data-index');
        this.secondCard.icon = event.currentTarget.getAttribute('data-card');
        this.secondCard.iteration = event.currentTarget.getAttribute('data-iteration');

        // Check if found
        if (this.firstCard.icon === this.secondCard.icon && this.firstCard.iteration !== this.secondCard.iteration) {
          this.found.push(parseInt(this.firstCard.index, 10));
          this.found.push(parseInt(this.secondCard.index, 10));

          setTimeout(() => {
            this.$audio.play('check');
          }, 200);

          if (this.found.length === this.cards.length) {
            setTimeout(() => {
              this.$sendEvent('Complete game', this.$route.params.scene);
              console.log('All found!');
              this.win = true;
              this.$store.commit('SET_GAME_COMPLETED', 'memory');
            }, 2000);
          }
        }
        this.stopped = true;
        setTimeout(() => this.reset(), 1000);
      }
    },
    reset() {
      this.stopped = false;
      this.firstCard = { index: null, icon: null, iteration: null };
      this.secondCard = { index: null, icon: null, iteration: null };
    },
    onLeave(el, done) {
      leave.get('memory')(el).then(done);
    },
    onEnter(el, done) {
      enterBlockText(el).then(done);

      setTimeout(() => CardsEngine.toNextCard(this.nextLink), 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.memory {
  .game__stage {
    width: grid-size(16);
    height: grid-size(24.3);

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);

    gap: grid-size(0.5);

    @media all and (min-height: 800px){
      width: grid-size(20);
      height: grid-size(30);

      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(6, 1fr);
    }

    @include mq(ipadP) {
      width: grid-size(15);
      height: grid-size(10);

      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(4, 1fr);

      gap: grid-size(0.2);
    }
  }

  .game__card {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 1px 14px rgba($color: #000000, $alpha: 0.16);
    transition: box-shadow .6s $inOutSine;

    &-front {
      @include full();
      background-position: center center;
      background-size: cover;
      transition: transform .8s $inOutSine;
      transform: scale(1.2);
    }

    &-back {
      @include full();
      background-color: #6480A5;

      clip-path: inset(0 0 0 0);
      transition: clip-path .6s $inOutSine;
      transform-origin: 50% 50%;
      transform: rotate(270deg) scale(1.45);

      i {
        @include full();
        background-image: url('/assets/img/memory/back.png');
        background-position: center center;
        background-size: 30% 30%;
        background-repeat: no-repeat;
        border-radius: inherit;
        transform: rotate(-270deg);
      }
    }

    &.active,
    &.found {
      box-shadow: 5px 6px 14px rgba($color: #000000, $alpha: 0.5);

      .game__card-front {
        transform: scale(1);
      }

      .game__card-back {
        clip-path: inset(0 0 0 100%);
      }
    }

    // &.found {
    //   // Some special style for found card
    // }
  }
}
</style>
