<template>
  <div class="game crosswords" @mousemove="move" @touchmove="move">
    <transition
      :css="false"
      mode="out-in"
      @leave="onLeave"
      @enter="onEnter"
    >
      <div v-if="win" key="win" class="game__win">
        <BaseTitleText :content="$translate(gameEnded ? 'end-game' : 'crosswords').titleText" />

        <BaseCta
          v-if="gameEnded"
         :content="$translate('endGameCta')"
         :href="$translate('end-game').nextLink"
          @mouseenter.native="$eventBus.$emit(`${nextLink}-enter`)"
          @mouseleave.native="$eventBus.$emit(`${nextLink}-leave`)"
        />
        <BaseCta
          v-else
          :content="$translate('nextGameCta')"
          :to="`/${$route.params.lang}/${nextLink}`"
          @click.native="$sendEvent('Select game', nextLink);"
          @mouseenter.native="$eventBus.$emit(`${nextLink}-enter`)"
          @mouseleave.native="$eventBus.$emit(`${nextLink}-leave`)"
        />
      </div>

      <div v-else key="in-game" class="game__wrapper">
        <div class="game__stage" ref="stage">
          <span
            v-for="(letter, index) in letters.flat()"
            :key="index"
            class="game__letter"
            :data-row="Math.floor(index/letters.length)"
            :data-column="index%letters.length"
            @mousedown="down"
            @mouseup="up"
            @touchstart="down"
            @touchend="up"
            v-html="letter"
          >
          </span>

          <div
            class="game__picker"
            v-show="segment.width > 0"
            :style="{
              width: `${segment.width}px`,
              height: `${segment.height}px`,
              top: `${segment.top}px`,
              left: `${segment.left}px`,
              transform: `rotate(${segment.rotate}deg)`,
              transformOrigin: segment.transformOrigin,
            }"
          />
        </div>

        <div class="game__list">
          <span v-for="(word, index) in words" :key="index">
            <span class="game__list-word" :data-word="word.toLowerCase()">{{ word }}</span>
            <span v-if="index !== words.length - 1"> - </span>
          </span>
        </div>

        <div class="game__footer">{{ $translate('crosswords').inGame }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseTitleText from '@/components/BaseTitleText';
import BaseCta from '@/components/BaseCta';
import { leave } from '@/scripts/animations';
import { enterBlockText } from '@/scripts/transitions/blockText';
import CardsEngine from '@/scripts/CardsEngine';

export default {
  name: 'WordSeeker',

  components: {
    BaseTitleText,
    BaseCta,
  },

  data() {
    return {
      mouseDown: false,
      start: { x: -1, y: -1 },
      end: { x: -1, y: -1 },
      foundedWords: [],
      segment: {
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        rotate: 0,
        transformOrigin: '',
      },
      startElement: null,
      endElement: null,
      win: false,
    };
  },

  computed: {
    letters() {
      return this.$translate('crosswords').letters;
    },
    words() {
      return this.$translate('crosswords').words;
    },
    gameEnded() {
      const endedArray = Object.values(this.$store.state.gameEnded);
      return endedArray.every((game) => game);
    },
    nextLink() {
      let link = 'idroscalo';

      if (this.$store.state.gameEnded.memory) link = 'galleria-strasburgo';

      return link;
    },
  },

  methods: {
    down(event) {
      this.mouseDown = true;
      this.$audio.play('click');
      const { left, top } = event.currentTarget.getBoundingClientRect();
      const { left: rectLeft, top: rectTop, height: rectHeight } = this.$refs.stage.getBoundingClientRect();

      const squareSize = rectHeight / this.letters.length;
      this.segment.height = squareSize;
      this.segment.transformOrigin = `${squareSize / 2}px ${squareSize / 2}px`;

      this.start = { x: left - rectLeft, y: top - rectTop };
      this.startElement = event.currentTarget;
    },
    move(event) {
      let x;
      let y;

      if (!this.mouseDown) return;

      if (event.type === 'touchmove') {
        x = event.touches[0].clientX;
        y = event.touches[0].clientY;
      } else {
        x = event.clientX;
        y = event.clientY;
      }
      const target = document.elementFromPoint(x, y);

      if (!target.classList.contains('game__letter')) return;

      const { left, top } = target.getBoundingClientRect();

      const { left: rectLeft, top: rectTop, height: rectHeight } = this.$refs.stage.getBoundingClientRect();
      this.end = { x: left - rectLeft, y: top - rectTop };
      const squareSize = rectHeight / this.letters.length;
      this.segment.width = this.getDistance(this.start, this.end) + squareSize;

      this.segment.left = this.start.x;
      this.segment.top = this.start.y;
      this.segment.rotate = 45 * Math.round(this.getAngle(this.start, this.end) / 45);
      this.endElement = target;
    },
    up() {
      this.mouseDown = false;
      const isH = [0, 90, 180, 270, 360].includes(this.segment.rotate);
      // if (!isH) {
      //   const i = Math.sqrt(Math.pow(30, 2) + Math.pow(30, 2)); // eslint-disable-line
      //   this.segment.width = i * Math.round(this.segment.width / i);
      // }
      const start = {
        x: parseInt(this.startElement.getAttribute('data-column'), 10),
        y: parseInt(this.startElement.getAttribute('data-row'), 10),
      };
      const end = {
        x: parseInt(this.endElement.getAttribute('data-column'), 10),
        y: parseInt(this.endElement.getAttribute('data-row'), 10),
      };

      // Check if the word matches
      let actives = null;
      // Swap if reversed
      if (end.x < start.x) { const o = start.x; start.x = end.x; end.x = o; }
      if (end.y < start.y) { const o = start.y; start.y = end.y; end.y = o; }
      if (!isH) {
        let found = 0;
        actives = [...this.$el.querySelectorAll('.game__letter')].filter((letter) => {
          const i = {
            x: parseInt(letter.getAttribute('data-column'), 10),
            y: parseInt(letter.getAttribute('data-row'), 10),
          };
          const test = i.x === (start.x + found) && i.y === (start.y + found) && i.x >= start.x && i.x <= end.x && i.y >= start.y && i.y <= end.y;
          if (test) found += 1;
          return test;
        });
      } else {
        // Fix for fake ending target
        const horizontal = [360, 180, 0].includes(this.segment.rotate);
        const vertical = [90, 270].includes(this.segment.rotate);
        if (horizontal) { end.y = start.y; }
        if (vertical) { end.x = start.x; }
        actives = [...this.$el.querySelectorAll('.game__letter')].filter((letter) => {
          const i = {
            x: parseInt(letter.getAttribute('data-column'), 10),
            y: parseInt(letter.getAttribute('data-row'), 10),
          };
          return i.x >= start.x && i.x <= end.x && i.y >= start.y && i.y <= end.y;
        });
      }
      const word = actives.map((el) => el.innerText).join('').toLowerCase();
      if (this.words.map((w) => w.toLowerCase()).includes(word)) {
        this.foundedWords.push(word);
        this.$audio.play('check');
        this.$el.querySelector(`[data-word="${word}"]`).classList.add('found');
        const n = this.$el.querySelector('.game__picker').cloneNode();
        n.classList.remove('.game__picker');
        n.classList.add('.game__picker-static');
        this.$refs.stage.appendChild(n);

        // win?
        if (this.foundedWords.length >= this.words.length) {
          this.$sendEvent('Complete game', this.$route.params.scene);
          this.win = true;
          this.$store.commit('SET_GAME_COMPLETED', 'crosswords');
        }
      }
    },
    getDistance(a, b) {
      return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2)); // eslint-disable-line
    },
    getAngle(a, b) {
      const angle = 180 + (Math.atan2(a.y - b.y, a.x - b.x) * 180) / Math.PI;
      return angle;
    },
    onLeave(el, done) {
      leave.get('crosswords')(el).then(done);

      setTimeout(() => CardsEngine.toNextCard(this.nextLink), 2000);
    },
    onEnter(el, done) {
      enterBlockText(el).then(done);
    },
  },
};
</script>

<style lang="scss" scoped>
.crosswords {
  .game__wrapper {
    padding-left: grid-size(3);
    padding-right: grid-size(3);
  }

  .game__stage {
    position: relative;
    width: grid-size(22);
    height: grid-size(22);
    padding: grid-size(0.5);

    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: repeat(11, 1fr);

    background-color: #B8B4E2;
    border-radius: 5px;

    align-items: stretch;
    justify-content: stretch;

    @include mq(ipadP) {
      width: grid-size(11);
      height: grid-size(11);
    }
  }

  .game__letter {
    color: #565D42;
    font-size: grid-size(1);
    line-height: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    @include mq(ipadP) {
      font-size: grid-size(0.4);
    }
  }

  .game__picker {
    position: absolute;
    pointer-events: none;

    background-image: url('/assets/img/wordseeker/selection.svg');
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .game__list {
    margin-top: grid-size(1);
    bottom: grid-size(2);
    width: 100%;
    text-align: center;
    color: $color-dark;

    @include mq(ipadP) {
      margin-top: grid-size(0.2);
    }

    & > span > span {
      display: inline-block;
      padding-left: 3px;
      padding-right: 3px;
    }

    &-word {
      position: relative;

      &::after {
        content: ' ';
        background-size: 100% 20%;
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        padding-left: 1em;
        padding-right: 1em;

        clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);

        transition: clip-path 0.3s ease;
      }

      &.found {
        &::after {
          background-image: url('/assets/img/wordseeker/stroke.svg');

          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
      }
    }
  }
}
</style>
