<template>
  <div class="game" data-id="game" :data-game="$route.params.game">
    <component :is="$route.params.game" />
  </div>
</template>

<script>
import Memory from '@/components/games/Memory';
import Crosswords from '@/components/games/Crosswords';
import Connects from '@/components/games/Connects';

export default {
  name: 'Game',

  components: {
    Memory,
    Crosswords,
    Connects,
  },
};
</script>
