<template>
  <div class="game connects" @mouseup="$emit('mouseup')">
    <transition
      :css="false"
      mode="out-in"
      @leave="onLeave"
      @enter="onEnter"
    >
      <div v-if="win" key="win" class="game__win">
        <BaseTitleText :content="$translate(gameEnded ? 'end-game' : 'connects').titleText" />

        <BaseCta v-if="gameEnded"
          :content="$translate('endGameCta')"
          :href="$translate('end-game').nextLink"
          @mouseenter.native="$eventBus.$emit(`${nextLink}-enter`)"
          @mouseleave.native="$eventBus.$emit(`${nextLink}-leave`)"
        />
        <BaseCta v-else
          :content="$translate('nextGameCta')"
          :to="`/${$route.params.lang}/${nextLink}`"
          @click.native="$sendEvent('Select game', nextLink)"
          @mouseenter.native="$eventBus.$emit(`${nextLink}-enter`)"
          @mouseleave.native="$eventBus.$emit(`${nextLink}-leave`)"
        />
      </div>

      <div v-else key="in-game" class="game__wrapper">
        <div class="game__levels">
          <transition
            :css="false"
            mode="out-in"
            @leave="onLevelLeave"
            @enter="onLevelEnter"
            @css="false"
          >
            <LevelOne v-if="currentLevel === 1" ref="level" @level-up="onLevelUP" />
            <!--<LevelTwo v-if="currentLevel === 2" ref="level" @level-up="onLevelUP" />-->
            <LevelThree v-if="currentLevel === 2" ref="level" @level-up="onWin" />
          </transition>
        </div>

        <div class="game__footer">{{ $translate('connects').inGame }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseTitleText from '@/components/BaseTitleText';
import BaseCta from '@/components/BaseCta';
import { init, enter, leave } from '@/scripts/animations';
import { enterBlockText } from '@/scripts/transitions/blockText';
import CardsEngine from '@/scripts/CardsEngine';
import LevelOne from './levels/LevelOne';
// import LevelTwo from './levels/LevelTwo';
import LevelThree from './levels/LevelThree';

export default {
  name: 'Connects',

  components: {
    BaseTitleText,
    BaseCta,
    LevelOne,
    // LevelTwo,
    LevelThree,
  },

  data() {
    return {
      currentLevel: 1,
      win: false,
    };
  },

  computed: {
    gameEnded() {
      const endedArray = Object.values(this.$store.state.gameEnded);
      return endedArray.every((game) => game);
    },
    nextLink() {
      let link = 'via-chiossetto';

      if (this.$store.state.gameEnded.crosswords) link = 'idroscalo';

      return link;
    },
  },

  methods: {
    onLevelUP() {
      this.currentLevel += 1;
    },
    onWin() {
      this.$sendEvent('Complete game', this.$route.params.scene);
      this.win = true;
      this.$store.commit('SET_GAME_COMPLETED', 'connects');
    },
    onLeave(el, done) {
      leave.get('connects')(el).then(done);
    },
    onEnter(el, done) {
      enterBlockText(el).then(done);

      setTimeout(() => CardsEngine.toNextCard(this.nextLink), 2000);
    },
    onLevelLeave(el, done) {
      leave.get('connects')(el).then(done);
    },
    onLevelEnter(el, done) {
      init.get('connects')(el);

      enter.get('connects')(el).then(done);
    },
  },
};
</script>

<style lang="scss" scoped>
.connects {
  @include full();
}

.game__wrapper::v-deep {
  .game__stage {
    display: grid;
    grid-template-columns: grid-size(10);
    grid-template-rows: grid-size(10);
    grid-template-areas: 'stage';

    cursor: move;

    @include mq($until: ipadP) {
      grid-template-columns: grid-size(20);
      grid-template-rows: grid-size(20);
    }

    &--movable,
    &--static-line,
    &--static-square,
    &--lines-movable,
    &--radiant {
      grid-area: stage;
    }

    &--static-line,
    &--lines-movable {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(6, 1fr);

      z-index: 1;
    }

    &--movable,
    &--static-square {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-template-areas:
        'top-left top-center top-right'
        'center-left center-center center-right'
        'bottom-left bottom-center bottom-right';

      justify-items: center;
      align-items: center;
    }

    &--static-line {
      background-color: rgba(0, 0, 0, .1);
      border-radius: 15px;
    }

    &--static-square {
      z-index: 2;
    }

    &--movable {
      z-index: 2;
    }

    &--radiant {
      padding: 10%;
      opacity: 0;
      stroke-dasharray: 786;
      stroke-dashoffset: 786;
      stroke: #6A6A6A;
      stroke-width: 250;

      //z-index: -1;
      pointer-events: none;
    }

    &--tutorial {
      position: absolute;
      width: grid-size(10);
      height: grid-size(10);
      z-index: 99999;
      background-color: rgba(0, 0, 0, .1);
      border-radius: 15px;
      user-select: none;
      pointer-events: none;

      @include mq($until: ipadP) {
        width: grid-size(20);
        height: grid-size(20);
      }

      &-explenation {
        position: absolute;
        top: grid-size(10);
        left: 0px;
        width: 100%;
        padding: grid-size(0.5) 0;
        text-align: center;
        color: $color-dark;
        @include font-size($fluid-m...);

        @include mq($until: ipadP) {
          top: grid-size(20.5);
        }
      }

      &-image {
        width: 100%;
      }
    }
  }

  .game__square,
  .game__dot {
    width: grid-size(1.8);
    height: grid-size(1.8);

    @include mq(ipadP) {
      width: grid-size(0.9);
      height: grid-size(0.9);
    }

    &[data-y="1"][data-x="1"] {
      width: grid-size(2.3);
      height: grid-size(2.3);

      @include mq(ipadP) {
        width: grid-size(1.4);
        height: grid-size(1.4);
      }
    }
  }

  .game__static-line,
  .game__movable-line {
    --line-height: 4vw;

    @include mq(ipadP) {
      --line-height: 2vw;
    }

    position: relative;
    transform-origin: center center;
    border-radius: calc(var(--line-height) / 2);

    &[data-left="0"] {
      grid-column-start: 2;
    }

    &[data-left="1"] {
      grid-column-start: 4;
    }

    &[data-left="2"] {
      grid-column-start: 6;
    }

    &[data-top="0"] {
      grid-row-start: 2;
    }

    &[data-top="1"] {
      grid-row-start: 4;
    }

    &[data-top="2"] {
      grid-row-start: 6;
    }

    &:not([data-vertical]) {
      width: calc(100% + var(--line-height));
      height: var(--line-height);
      left: calc(var(--line-height) / 2 * -1);

      transform: translate(0, -50%);
    }

    &[data-vertical] {
      width: var(--line-height);
      height: calc(100% + var(--line-height));
      left: 0;
      top: calc(var(--line-height) / 2 * -1);

      transform: translate(-50%, 0);
    }

    &:not([data-span="2"]) {
      grid-column-end: span 2;
      grid-row-end: span 2;
    }

    &[data-span="2"] {
      grid-column-end: span 4;
      grid-row-end: span 4;
    }
  }

  .game__static-line {
    background-color: #AFDBFB;
  }

  .game__movable-line {
    background-color: #9D9B6B;
  }

  .game__bear,
  .game__heart {
    z-index: 3;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .game__bear {
    width: grid-size(2.5);
    height: grid-size(2.5);
    background-image: url('/assets/img/connects/bear.png');

    cursor: grab;

    @include mq(ipadP) {
      width: grid-size(1.3);
      height: grid-size(1.3);
    }
  }

  .game__heart {
    width: grid-size(4);
    height: grid-size(4);
    pointer-events: none;
    background-image: url('/assets/img/connects/heart.png');

    @include mq(ipadP) {
      width: grid-size(2.1);
      height: grid-size(2.1);
    }
  }

  .game__explosion {
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-image: url('/assets/img/connects/explosion.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
</style>
